<template>
  <section id="breadcrumbs">
    <div class="bread">
      <div class="bread__item" v-for="(item, i) in links" :key="i">
        <router-link
          style="text-decoration: none"
          :to="{ name: item.to.name, params: { uuid: item.to.params } }"
          v-if="item.link"
        >
          <div
            class="bread__link"
            :class="dark == 1 ? 'white--text' : 'black--text'"
          >
            {{ item.name }}
            <v-icon class="mx-2" size="28">mdi-chevron-right</v-icon>
          </div>
        </router-link>

        <div v-else class="bread__link" :class="dark == 1 ? '' : 'black--text'">
          {{ item.name }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["links", "dark"],
};
</script>