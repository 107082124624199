<template>
  <section id="course-details">
    <v-row v-if="!loading">
      <v-col cols="12" xl="8">
        <breadcrumbs :links="links" :dark="user.dark" />
        <v-divider />
        <v-row no-gutters>
          <v-col cols="12" lg="8">
            <v-img
              height="520"
              width="100%"
              v-if="course.image"
              class="mx-auto"
              :src="`${course.aws}/courses/${course.uuid}/image/${course.image.image}`"
            />
            <v-avatar
              v-else
              height="520"
              width="100%"
              tile
              class="mx-auto"
              color="#95a5a6"
            ></v-avatar>
            <div>
              <v-tabs color="success" v-model="item">
                <v-tab v-for="(item, i) in items" :key="i">
                  <div class="caption">{{ item }}</div>
                </v-tab>
                <div class="d-flex align-center">
                  <v-btn text @click="report">
                    Export Users Report
                    <v-icon right>
                      mdi-export
                    </v-icon>
                  
                  </v-btn>
                </div>
                
                <v-spacer />
                <div class="d-flex align-center mr-5">
                  <v-btn
                    text
                    color="primary"
                    @click="approve"
                    class="caption"
                    v-if="
                      course.status == 0 ||
                      course.status == 1 ||
                      course.status == 3
                    "
                    >Approve</v-btn
                  >
                  <v-btn
                    v-else
                    depressed
                    color="success"
                    readonly
                    class="caption"
                    >Approved</v-btn
                  >
                  <v-btn v-if="course.status == 3" readonly text class="caption"
                    >Drafted</v-btn
                  >
                  <v-btn
                    v-else
                    text
                    color="danger"
                    @click="draft"
                    class="caption"
                    >Draft</v-btn
                  >
                </div>
              </v-tabs>
              <v-tabs-items color="success" v-model="item">
                <v-tab-item v-for="(item, i) in items" :key="i">
                  <Details
                    v-if="item === 'Course Details'"
                    :course="course"
                    :dark="user.dark"
                  />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
          <v-col cols="12" lg="4" class="pa-5 secondary">
            <Lessons :lessons="course.lessons" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="body-1" v-else>Loading...</div>

    <snackbar
      :snack="dialog"
      :text="text"
      timeout="2000"
      @close="dialog = !dialog"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Breadcrumbs from "../../constants/global-components/Breadcrumbs.vue";
import Lessons from "../components/course/Lessons.vue";
import Details from "../components/course/Details.vue";

export default {
  components: {
    Lessons,
    Breadcrumbs,
    Details,
  },
  data: () => ({
    item: null,
    dialog: false,
    text: "",
    loading: true,
    items: ["Course Details"],
    links: [
      {
        name: "Courses",
        to: {
          name: "Admin Courses",
          params: "",
        },
        link: true,
      },
    ],
  }),
  computed: {
    ...mapState("admin", {
      course: (state) => state.course,
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.showCourseAction(this.$route.params.uuid).then(() => {
      this.loading = false;
      this.links.push({
        name: this.course.title,
        to: { name: "", params: "" },
        link: false,
      });
    });
  },
  methods: {
    ...mapActions("admin", ["showCourseAction", "statusCourseAction", 'studentsReportAction']),

    approve() {
      if (confirm("Approve this course?")) {
        this.status(2);
        this.dialog = true;
        this.text = "Course Approved";
      }
    },
    draft() {
      if (confirm("Draft this course?")) {
        this.status(3);
        this.dialog = true;
        this.text = "Course Approved";
      }
    },
    status(action) {
      this.statusCourseAction({
        action: action,
        uuid: this.course.uuid,
      }).then(() => {
        if (this.$errors("admin")) {
          console.log("errors");
          return;
        }
      });
    },

    report() {
      this.studentsReportAction(this.course.id)
    }
  },
};
</script>