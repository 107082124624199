<template>
  <section class="mt-5">
    <v-card flat>
      <v-card-text>
        <v-sheet outlined class="pa-5">
          <div class="caption mb-2">Teacher</div>
          <div class="text-h6">
            {{ `${course.teacher.firstname} ${course.teacher.lastname}` }}
          </div>
          <div class="caption">{{ `${course.teacher.email}` }}</div>
        </v-sheet>
        <v-sheet outlined class="pa-5 my-5">
          <div class="caption mb-2">About Course</div>
          <div class="d-flex flex-wrap justify-space-between align-center">
            <div class="mx-3">
              <div class="text-h6">
                {{ $helpers.dateDiff(course.created_at) }}
              </div>
              <small>ADDED</small>
            </div>
            <v-divider vertical class="hidden-sm-and-down" />
            <div class="mx-4">
              <div class="text-h6">{{ course.duration_count }}</div>
              <small>DURATION COUNT</small>
            </div>
            <v-divider vertical class="hidden-sm-and-down" />
            <div class="mx-4">
              <div class="text-h6">{{ course.duration_type }}</div>
              <small>DURATION TYPE</small>
            </div>
            <v-divider vertical class="hidden-sm-and-down" />
            <div class="mx-4">
              <div class="text-h6">
                {{ course.enrolled_students_count }}
              </div>
              <small>ENROLLED USER</small>
            </div>
          </div>
        </v-sheet>
        <v-sheet outlined class="pa-5">
          <div class="caption mb-2">Description</div>

          <div class="body-1" v-html="course.description"></div>
        </v-sheet>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  props: ["course", "dark"],
};
</script>