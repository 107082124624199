<template>
  <section>
    <div class="body-1 mb-5">Topic Navigation</div>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="item in lessons"
        :key="item.id"
        :readonly="item.type == 'Assessment'"
      >
        <v-expansion-panel-header>
          <template v-slot:default>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <div class="body-1 font-weight-bold">
                  {{ item.title.slice(0, 20)
                  }}{{ item.title.length > 20 ? "..." : "" }}
                </div>
                <div class="caption" v-text="`${item.type}`" />
              </div>
              <v-spacer></v-spacer>
              <v-icon
                class="mr-2"
                size="20"
                :color="item.status == 1 ? 'success' : ''"
              >
                {{
                  item.status == 1
                    ? "mdi-checkbox-marked"
                    : "mdi-checkbox-blank"
                }}
              </v-icon>
            </div>
          </template>
          <template v-slot:actions>
            <v-icon
              v-if="item.type == 'Assessment'"
              size="22"
              :class="item.assessment_count == 1 ? 'white--text' : ''"
            >
              {{
                item.assessment_count == 0
                  ? "mdi-order-bool-descending-variant"
                  : "mdi-file-outline"
              }}
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-divider />
        <v-expansion-panel-content class="mt-4">
          <Topic :topics="item.topics" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
import Topic from "./Topic.vue";
export default {
  components: {
    Topic,
  },
  props: ["lessons"],
};
</script>