<template>
  <section class="mt-2">
    <v-expansion-panels>
      <v-expansion-panel v-for="topic in topics" :key="topic.id">
        <v-expansion-panel-header>
          <template v-slot:default>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <div class="body-2 font-weight-bold" v-text="topic.title" />
                <div
                  class="caption"
                  v-text="
                    topic.type == 'v'
                      ? 'Video'
                      : topic.type == 'd'
                      ? 'Document'
                      : 'Youtube'
                  "
                />
              </div>
              <v-spacer></v-spacer>
              <v-icon
                class="mr-2"
                size="20"
                :color="topic.status == 1 ? 'success' : ''"
              >
                {{
                  topic.status == 1
                    ? "mdi-checkbox-marked"
                    : "mdi-checkbox-blank"
                }}
              </v-icon>
            </div>
          </template>
        </v-expansion-panel-header>
        <v-divider />
        <v-expansion-panel-content>
          <div class="caption ma-2">ASSESSMENTS</div>
          <v-card
            v-for="assessment in topic.assessments"
            :key="assessment.id"
            class="my-2"
          >
            <v-card-text>
              <div class="body-2 black--text">{{ assessment.title }}</div>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
export default {
  props: ["topics"],
};
</script>